import React, { forwardRef } from "react"
import Labelled from "./Labelled"

function TextField(props, ref) {
  const {
    id,
    multiline,
    label,
    name,
    placeholder,
    required,
    onChange,
    onBlur,
    type = "text",
  } = props

  if (multiline) {
    return (
      <div>
        <Labelled id={id} label={label} required={required}>
          <textarea
            ref={ref}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            placeholder={placeholder}
            type={type}
            name={name}
            rows={multiline}
            required={required}
            className="py-3 border-transparent w-full bg-gray-100"
          />
        </Labelled>
      </div>
    )
  }

  return (
    <div>
      <Labelled id={id} label={label} required={required}>
        <input
          ref={ref}
          id={id}
          onBlur={onBlur}
          placeholder={placeholder}
          type={type}
          name={name}
          required={required}
          className="py-3 border-transparent w-full bg-gray-100"
        />
      </Labelled>
    </div>
  )
}

export default forwardRef(TextField)

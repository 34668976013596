import { GraphQLClient, gql } from "graphql-request"

const endpoint = "https://api.hpcconsulting.co.kr/wp/graphql"

export const client = new GraphQLClient(endpoint, {
  headers: {
    // authorization: 'Bearer MY_TOKEN',
  },
})

export default client

// async function main() {
//   const endpoint = "http://api.hpcconsulting.co.kr/wp/graphql"

//   const graphQLClient = new GraphQLClient(endpoint, {
//     headers: {
//       // authorization: 'Bearer MY_TOKEN',
//     },
//   })

//   const mutation = gql`
//     mutation createContact($title: String!, $releaseDate: Int!) {
//       insert_movies_one(object: { title: $title, releaseDate: $releaseDate }) {
//         title
//         releaseDate
//       }
//     }
//   `

//   const variables = {
//     title: "Inception",
//     releaseDate: 2010,
//   }
//   const data = await graphQLClient.request(mutation, variables)

//   console.log(JSON.stringify(data, undefined, 2))
// }

import React from "react"
import Seo from "components/Seo"
import PageHeader from "components/PageHeader"
import Section from "components/Section"
import ContactForm from "components/ContactForm"

import LocalPhoneIcon from "@mui/icons-material/LocalPhone"
import MailIcon from "@mui/icons-material/Mail"
import RoomIcon from "@mui/icons-material/Room"
import IconKakao from "images/icons/icon-kakao.png"

export default function ContactPage() {
  return (
    <>
      <Seo
        title={"상담신청"}
        description="궁금하신 점을 간략히 남겨주시면 전문 컨설턴트가 빠른 시일 내에 연락 드리겠습니다."
      />
      <PageHeader title={"상담신청"}></PageHeader>
      <div className="text-center"></div>
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8 xl:-mx-16">
          <div className="w-full lg:w-7/12 px-4 lg:px-8 xl:px-16 border-r border-gray-100">
            <div>
              <div className="mb-8">
                <h4 className="text-xl lg:text-2xl font-medium">
                  전문 컨설턴트 상담신청
                </h4>
                <p className="text-gray-500 mt-1 lg:mt-2 break-words">
                  궁금하신 점을 간략히 남겨주시면 빠른 시일 내에 연락
                  드리겠습니다.
                </p>
              </div>
              <ContactForm />
            </div>
          </div>
          <div className="w-full lg:w-5/12 px-4 lg:px-8 xl:px-16">
            <div>
              <div className="mt-8 lg:mt-0 mb-8">
                <h4 className="text-xl lg:text-2xl font-medium mb-3">
                  카카오톡 상담은 어떠세요?
                </h4>
                <a
                  href="http://pf.kakao.com/_dxcxoMxb"
                  target="_blank"
                  className=" leading-none inline-flex items-center flex-row flex-wrap py-2 px-3 rounded-md bg-yellow-300"
                >
                  <img
                    src={IconKakao}
                    alt="카카오톡 상담"
                    className="h-8 mr-2"
                  />
                  <span className="font-bold" style={{ color: "#3e2723" }}>
                    카카오톡 상담하기
                  </span>
                </a>
              </div>
              <div className="mb-8">
                <h4 className="text-xl lg:text-2xl font-medium mb-3 ">
                  지금 바로 상담이 필요하신가요?
                </h4>
                <ul className="flex flex-col w-full">
                  <li className="mb-5">
                    <div className="flex flex-row flex-wrap items-start">
                      <LocalPhoneIcon className="text-primary mr-5" />
                      <div>
                        <h4 className="text-gray-500">대표번호</h4>
                        <p className="text-lg font-medium text-gray-900">
                          <a href={`tel:${process.env.GATSBY_COMPANY_PHONE}`}>
                            {process.env.GATSBY_COMPANY_PHONE}
                          </a>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-5">
                    <div className="flex flex-row flex-wrap items-start">
                      <MailIcon className="text-primary mr-5" />
                      <div>
                        <h4 className="text-gray-500">이메일</h4>
                        <p className="text-lg font-medium text-gray-900">
                          <a
                            href={`mailto:${process.env.GATSBY_COMPANY_CONTACT}`}
                          >
                            {process.env.GATSBY_COMPANY_CONTACT}
                          </a>
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mb-5">
                    <div className="flex flex-row flex-wrap items-start">
                      <RoomIcon className="text-primary mr-5" />
                      <div>
                        <h4 className="text-gray-500">주소</h4>
                        <p className="text-lg font-medium text-gray-900 mb-3">
                          {process.env.GATSBY_COMPANY_ADDRESS}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-8 lg:py-24">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <h4 className="text-xl lg:text-2xl font-medium text-gray-900 mb-8 lg:mb-12 break-words lg:text-center">
            Q. 상담신청 후 컨설팅은 어떻게 이루어지나요?
          </h4>
          <div className="relative flex flex-col lg:flex-row">
            <div
              className="hidden lg:block mt-3 h-1 bg-gray-300 absolute top-0 bottom-0 z-10 left-1/2 -translate-x-1/2 "
              style={{ width: "80%" }}
            ></div>
            <div className="ml-3 w-1 h-full bg-gray-300 absolute left-0 top-0 bottom-0 z-10 block lg:hidden"></div>
            {[
              {
                label: "상담신청",
                description: "모든 상담 내용은 비밀이 보장됩니다.",
              },
              {
                label: "담당 컨설턴트 배정",
                description:
                  "상담 내용을 검토 후 분야의 전문 컨설턴트를 배정합니다.",
              },
              {
                label: "기업분석 및 솔루션 제안",
                description:
                  "면밀하게 요구사항을 검토 후 실행 가능한 솔루션을 제공합니다.",
              },
              {
                label: "컨설팅 실행",
                description:
                  "전문 컨설턴트가 실행에도 함께 참여해 결과를 도출합니다.",
              },
              {
                label: "사후관리",
                description:
                  "컨설팅 실행 후 지속 경영이 이루어 지도록 관리됩니다.",
              },
            ].map((item, index) => {
              return (
                <div
                  key={`consulting-process-${index}`}
                  className="lg:w-1/5 flex flex-row lg:flex-col mb-8 lg:text-center lg:items-center"
                >
                  <div className="mb-3">
                    <span className="relative z-20 w-7 h-7 bg-primary text-white rounded-full flex items-center justify-center">
                      <span className="w-5 h-5 leading-none bg-white text-center rounded-full text-primary font-medium flex items-center justify-center">
                        {index + 1}
                      </span>
                    </span>
                  </div>
                  <div className="px-4">
                    <h4
                      className={
                        "font-medium text-gray-900 text-lg lg:text-base xl:text-lg"
                      }
                    >
                      {item.label}
                    </h4>
                    <p className="text-gray-500 mt-2 break-words block lg:hidden xl:block rounded-md">
                      {item.description}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )

  return (
    <div className="py-24">
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8">
          <div className="w-8/12 px-4 lg:px-8">
            <div className="max-w-2xl">
              <div className="mb-8">
                <Section.Headline>
                  <span className="break-words">
                    궁금하신 점을 간략히 남겨주시면 노무 전문 컨설턴트가 24시간
                    이내 답변을 드리겠습니다.
                  </span>
                </Section.Headline>
              </div>
              <ContactForm />
            </div>
          </div>
          <div className="w-4/12 px-4 lg:px-8">
            <div>
              <div>
                <h4 className="text-2xl font-medium text-gray-900 mb-8 break-words">
                  카카오톡 상담은 어떠세요?
                </h4>
              </div>

              <div className="max-w-md">
                <h4 className="text-2xl font-medium text-gray-900 mb-8 break-words">
                  상담신청 후 컨설팅은 어떻게 이루어지나요?
                </h4>
                <div className="relative">
                  <div className="ml-3 w-1 bg-gray-300 absolute left-0 top-0 bottom-0 z-10"></div>
                  {[
                    {
                      label: "상담신청",
                    },
                    {
                      label: "담당 컨설턴트 배정",
                      description:
                        "문의내용 검토 후 분야에 맞는 전문 컨설턴트를 신속히 배정합니다.",
                    },
                    {
                      label: "기업분석 및 솔루션 제안",
                      description:
                        "더 면밀하게 요구사항을 검토 후 실행 가능한 솔루션을 제공합니다.",
                    },
                    {
                      label: "컨설팅 실행",
                      description:
                        "전문 컨설턴트가 실행에도 참여하여 함께 결과를 만들어 냅니다.",
                    },
                    {
                      label: "사후관리",
                      description:
                        "컨설팅 종료 후에도 경영이 지속되는 동안 관리가 이루어집니다.",
                    },
                  ].map((item, index) => {
                    return (
                      <div
                        key={`consulting-process-${index}`}
                        className="flex flex-row items-start mb-8"
                      >
                        <div className="mr-3">
                          <span className="relative z-20 w-7 h-7 bg-primary text-white rounded-full flex items-center justify-center">
                            <span className="w-5 h-5 leading-none bg-white text-center rounded-full text-primary font-medium flex items-center justify-center">
                              {index + 1}
                            </span>
                          </span>
                        </div>
                        <div>
                          <h4
                            className={"font-medium text-gray-900 lg:text-lg"}
                          >
                            {item.label}
                          </h4>
                          <p className="text-gray-500 mt-2 break-words">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:container mx-auto px-4 lg:px-8">
        <div className="mt-24 mb-8">
          <h4 className="text-gray-900 text-2xl font-medium">
            지금 바로 상담이 필요하신가요?
          </h4>
        </div>
        <ul className="flex flex-col">
          <li className="mb-5">
            <h4 className="text-gray-500">이메일</h4>
            <p className="text-lg font-medium text-gray-900">
              support@hpcconsulting.co.kr
            </p>
          </li>
          <li className="mb-5">
            <h4 className="text-gray-500">연락처</h4>
            <p className="text-lg font-medium text-gray-900">02-1234-5678</p>
          </li>
          <li className="mb-5">
            <h4 className="text-gray-500">주소</h4>
            <p className="text-lg font-medium text-gray-900">
              서울시 강남구 남부순환로 2621, 1325호
            </p>
          </li>
        </ul>
      </div>
    </div>
  )

  return (
    <div>
      <div className="-mt-24">
        <div className="flex flex-row flex-wrap">
          <div className="w-1/2 bg-white flex items-center justify-center">
            <div>
              <div className="max-w-md">
                <h4 className="text-2xl font-medium text-gray-900 mb-8 break-words">
                  상담신청 후 컨설팅은 어떻게 이루어지나요?
                </h4>
                <div className="relative">
                  <div className="ml-3 w-1 bg-gray-300 absolute left-0 top-0 bottom-0 z-10"></div>
                  {[
                    {
                      label: "상담신청",
                    },
                    {
                      label: "담당 컨설턴트 배정",
                      description:
                        "문의내용 검토 후 분야에 맞는 전문 컨설턴트를 신속히 배정합니다.",
                    },
                    {
                      label: "기업분석 및 솔루션 제안",
                      description:
                        "깊은 기업 상담을 통해 더 면밀하게 요구사항을 검토 후 실행 가능한 솔루션을 제공합니다.",
                    },
                    {
                      label: "컨설팅 실행",
                      description:
                        "전문 컨설턴트가 실행에도 참여하여 함께 결과를 만들어 냅니다.",
                    },
                    {
                      label: "사후관리",
                      description:
                        "컨설팅 종료 후에도 경영이 지속되는 동안 관리가 이루어집니다.",
                    },
                  ].map((item, index) => {
                    return (
                      <div
                        key={`consulting-process-${index}`}
                        className="flex flex-row items-start mb-8"
                      >
                        <div className="mr-3">
                          <span className="relative z-20 w-7 h-7 bg-primary text-white rounded-full flex items-center justify-center">
                            <span className="w-5 h-5 leading-none bg-white text-center rounded-full text-primary font-medium flex items-center justify-center">
                              {index + 1}
                            </span>
                          </span>
                        </div>
                        <div>
                          <h4
                            className={"font-medium text-gray-900 lg:text-lg"}
                          >
                            {item.label}
                          </h4>
                          <p className="text-gray-500 mt-2 break-words">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="w-1/2 bg-gray-100 px-24  py-48">
            <ContactForm />
          </div>
        </div>
      </div>
      <div className="mb-24">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <h4 className="text-gray-900 text-2xl font-medium">
            지금 바로 상담이 필요하신가요?
          </h4>
          <p></p>
          <ul className="flex flex-row flex-wrap -mx-4 lg:-mx-8">
            <li className="border-r last:border-0 px-4 lg:px-8 lg:py-8">
              <h4 className="text-gray-500">이메일</h4>
              <p className="text-xl font-medium text-gray-900">
                support@hpcconsulting.co.kr
              </p>
            </li>
            <li className="border-r last:border-0 px-4 lg:px-8 lg:py-8">
              <h4 className="text-gray-500">연락처</h4>
              <p className="text-xl font-medium text-gray-900">02-1234-5678</p>
            </li>
            <li className="border-r last:border-0 px-4 lg:px-8 lg:py-8">
              <h4 className="text-gray-500">주소</h4>
              <p className="text-xl font-medium text-gray-900">
                서울시 강남구 남부순환로 2621, 1325호
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )

  return (
    <div className="">
      <PageHeader title={"온라인 상담"} />
      <div className="pb-24">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <div className="mb-16">
            <h4 className="text-2xl font-medium text-gray-900 mb-8 break-words">
              상담신청 후 컨설팅은 어떻게 이루어지나요?
            </h4>
            <div className="relative flex flex-row ">
              <div className="mt-3 w-full h-1 bg-gray-300 absolute left-0 top-0 bottom-0 z-10"></div>
              {[
                {
                  label: "상담신청",
                },
                {
                  label: "담당 컨설턴트 배정",
                  description:
                    "문의내용 검토 후 분야에 맞는 전문 컨설턴트를 신속히 배정합니다.",
                },
                {
                  label: "기업분석 및 솔루션 제안",
                  description:
                    "깊은 기업 상담을 통해 더 면밀하게 요구사항을 검토 후 실행 가능한 솔루션을 제공합니다.",
                },
                {
                  label: "컨설팅 실행",
                  description:
                    "전문 컨설턴트가 실행에도 참여하여 함께 결과를 만들어 냅니다.",
                },
                {
                  label: "사후관리",
                  description:
                    "컨설팅 종료 후에도 경영이 지속되는 동안 관리가 이루어집니다.",
                },
              ].map((item, index) => {
                return (
                  <div
                    key={`consulting-process-${index}`}
                    className="w-1/5 flex flex-col items-start mb-8"
                  >
                    <div className="mb-3">
                      <span className="relative z-20 w-7 h-7 bg-primary text-white rounded-full flex items-center justify-center">
                        <span className="w-5 h-5 leading-none bg-white text-center rounded-full text-primary font-medium flex items-center justify-center">
                          {index + 1}
                        </span>
                      </span>
                    </div>
                    <div>
                      <h4 className={"font-medium text-gray-900 lg:text-lg"}>
                        {item.label}
                      </h4>
                      <p className="text-gray-500 mt-2 break-words rounded-md">
                        {item.description}
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="p-8 shadow-lg max-w-screen-lg mx-auto">
            <ContactForm />
          </div>
        </div>
        <div className="w-full lg:container mx-auto px-4 lg:px-8 ">
          <div className="flex flex-row flex-wrap -mx-4 lg:-mx-8">
            <div className="order-2 lg:order-1 w-full lg:w-4/12 xl:w-5/12 px-4 lg:px-8 flex items-center lg:justify-center">
              <div className="mt-16 lg:mt-0 max-w-sm">
                <h4 className="text-2xl font-medium text-gray-900 mb-8 break-words">
                  상담신청 후 컨설팅은 어떻게 이루어지나요?
                </h4>
                <div className="relative">
                  <div className="ml-3 w-1 bg-gray-300 absolute left-0 top-0 bottom-0 z-10"></div>
                  {[
                    {
                      label: "상담신청",
                    },
                    {
                      label: "담당 컨설턴트 배정",
                      description:
                        "문의내용 검토 후 분야에 맞는 전문 컨설턴트를 신속히 배정합니다.",
                    },
                    {
                      label: "기업분석 및 솔루션 제안",
                      description:
                        "깊은 기업 상담을 통해 더 면밀하게 요구사항을 검토 후 실행 가능한 솔루션을 제공합니다.",
                    },
                    {
                      label: "컨설팅 실행",
                      description:
                        "전문 컨설턴트가 실행에도 참여하여 함께 결과를 만들어 냅니다.",
                    },
                    {
                      label: "사후관리",
                      description:
                        "컨설팅 종료 후에도 경영이 지속되는 동안 관리가 이루어집니다.",
                    },
                  ].map((item, index) => {
                    return (
                      <div
                        key={`consulting-process-${index}`}
                        className="flex flex-row items-start mb-8"
                      >
                        <div className="mr-3">
                          <span className="relative z-20 w-7 h-7 bg-primary text-white rounded-full flex items-center justify-center">
                            <span className="w-5 h-5 leading-none bg-white text-center rounded-full text-primary font-medium flex items-center justify-center">
                              {index + 1}
                            </span>
                          </span>
                        </div>
                        <div>
                          <h4
                            className={"font-medium text-gray-900 lg:text-lg"}
                          >
                            {item.label}
                          </h4>
                          <p className="text-gray-500 mt-2 break-words">
                            {item.description}
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="order-1 lg:order-2 w-full lg:w-8/12 xl:w-7/12 px-4 lg:px-8">
              <div className="shadow-md border border-gray-100">
                {/* <div className="bg-gray-100 py-5 px-4 lg:px-8">
                  <div className="flex flex-row items-center">
                    <div className="mr-5">
                      <div className="w-14 h-14">
                        <div className="bg-white rounded-md p-2">
                          <img src={EmailIcon} alt="" className="w-full" />
                        </div>
                      </div>
                    </div>
                    <p className="text-gray-900 font-medium lg:text-lg break-words max-w-sm">
                      궁금하신 점을 간략히 남겨주시면 전문 컨설턴트가 신중히
                      검토 후 24시간이내 연락드리겠습니다.
                    </p>
                  </div>
                </div> */}
                <div className="py-8 px-4 lg:px-8">
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mb-24">
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <h4 className="text-gray-900 text-2xl font-medium">
            지금 바로 상담이 필요하신가요?
          </h4>
          <p></p>
          <ul className="flex flex-row flex-wrap -mx-4 lg:-mx-8">
            <li className="border-r last:border-0 px-4 lg:px-8 lg:py-8">
              <h4 className="text-gray-500">이메일</h4>
              <p className="text-xl font-medium text-gray-900">
                support@hpcconsulting.co.kr
              </p>
            </li>
            <li className="border-r last:border-0 px-4 lg:px-8 lg:py-8">
              <h4 className="text-gray-500">연락처</h4>
              <p className="text-xl font-medium text-gray-900">02-1234-5678</p>
            </li>
            <li className="border-r last:border-0 px-4 lg:px-8 lg:py-8">
              <h4 className="text-gray-500">주소</h4>
              <p className="text-xl font-medium text-gray-900">
                서울시 강남구 남부순환로 2621, 1325호
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="hidden w-full lg:container mx-auto px-4 lg:px-8">
        {/* <div className="mb-8">
          <h4>주소</h4>
          <p className="text-gray-500">서울시 강남구 남부순환로 2621 1325호</p>
        </div>
        <h3 className="mb-3 font-medium text-xl">컨설팅 프로세스</h3>
        <div className="relative">
          <div className="ml-3 w-1 bg-gray-300 absolute left-0 top-0 bottom-0 z-10"></div>
          {[
            { label: "상담신청" },
            { label: "담당 컨설턴트 배정" },
            { label: "기업분석 및 솔루션 제안" },
            { label: "컨설팅 실행" },
            { label: "사후관리" },
          ].map((item, index) => {
            return (
              <div
                key={`consulting-process-${index}`}
                className="flex flex-row items-center mb-5"
              >
                <div className="mr-3">
                  <span className="relative z-20 w-7 h-7 bg-primary text-white rounded-full flex items-center justify-center">
                    <span className="w-5 h-5 leading-none bg-white text-center rounded-full text-primary font-medium flex items-center justify-center">
                      {index + 1}
                    </span>
                  </span>
                </div>
                <h4
                  className={`${
                    !index ? "font-medium text-gray-900" : "text-gray-500"
                  } font-gray-900`}
                >
                  {item.label}
                </h4>
              </div>
            )
          })}
        </div> */}

        <div className="flex flex-row flex-wrap -mx-4">
          <div className="w-full lg:w-6/12 px-4">
            <div className="mb-8">
              <Section.Headline eyebrow="Get in touch">
                무엇을 도와드릴까요?
              </Section.Headline>
              <p className="text-gray-500 mt-3">
                궁금하신 점을 남겨주시면 24시간 이내 신속히 연락드리겠습니다.
              </p>
            </div>
            <div className="max-w-lg bg-gray-50">
              <ContactForm />
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4"></div>
        </div>

        {/* <div className="flex flex-row flex-wrap -mx-4">
          <div className="w-full lg:w-1/2 px-4">
            <div className="py-8 lg:py-24">
              <div className="mb-8">
                <Section.Headline>
                  전문 노무 컨설턴트가 <br />
                  입주 상담을 도와드립니다
                </Section.Headline>
              </div>
              <ContactForm />
            </div>
          </div>
          <div className="w-full lg:w-1/2 px-4">
            <div>
              <div className="flex flex-row flex-wrap">
                <div className="mr-3"></div>
                <div>
                  <p>02-555-9839</p>
                </div>
              </div>
              <div className="flex flex-row flex-wrap">
                <div className="mr-3"></div>
                <div>
                  <p>contact@hpcconsulting.co.kr</p>
                </div>
              </div>
              <div className="flex flex-row flex-wrap">
                <div className="mr-3"></div>
                <div>
                  <a href="http://pf.kakao.com/_dxcxoMxb">카카오톡 연결</a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

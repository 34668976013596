import React from "react"

export default function Labelled({ label, id, required, children }) {
  return (
    <label htmlFor={id}>
      <span className="block text-gray-900 mb-1">
        {label} {required && <span className="text-red-500">*</span>}
      </span>
      {children}
    </label>
  )
}

import React, { useState } from "react"
import TextField from "./ui/TextField"
import Select from "./ui/Select"
import { useForm } from "react-hook-form"
import { gql } from "graphql-request"
import clinet from "lib/graphql"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"

export default function ContactForm() {
  const [sending, setSending] = useState(false)
  const [saved, setSaved] = useState(false)
  const [error, setError] = useState(false)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()

  const onSubmit = async data => {
    setSending(true)
    const mutation = gql`
      mutation createContact(
        $clientMutationId: String!
        $company: String!
        $description: String!
        $email: String!
        $name: String!
        $phone: String!
        $type: String!
      ) {
        createContact(
          input: {
            clientMutationId: $clientMutationId
            company: $company
            description: $description
            email: $email
            name: $name
            phone: $phone
            type: $type
          }
        ) {
          data
          success
          clientMutationId
        }
      }
    `

    const { company, email, name, phone, description, type } = data
    const variables = {
      type,
      company: company ? company : "N/A",
      email,
      name,
      phone,
      description: description ? description : "N/A",
      clientMutationId: "contact",
    }
    const response = await clinet.request(mutation, variables)

    const { createContact } = response
    const { success } = createContact

    if (success) {
      setSaved(true)
      setSending(false)
      reset()
    } else {
      setSending(false)
      setError(true)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="flex flex-row flex-wrap -mx-3">
            <div className="w-full lg:w-1/2 mb-5 px-3">
              <TextField
                label="성함"
                requiwred
                placeholder="홍길동"
                {...register("name", { required: true })}
              />
            </div>
            <div className="w-full lg:w-1/2 mb-5 px-3">
              <TextField
                label="회사명"
                placeholder="HPC컨설팅"
                {...register("company")}
              />
            </div>
          </div>
          <div className="flex flex-row flex-wrap -mx-3">
            <div className="w-full lg:w-1/2 mb-5 px-3">
              <TextField
                label="연락처"
                required
                placeholder="02-555-9838"
                {...register("phone", { required: true })}
              />
            </div>
            <div className="w-full lg:w-1/2 mb-5 px-3">
              <TextField
                label="이메일"
                type="email"
                required
                placeholder="contact@hpcconsulting.co.kr"
                {...register("email", { required: true })}
              />
            </div>
          </div>
          <div className="mb-5">
            <Select
              label={"컨설팅 분야"}
              required
              options={[
                { label: "인적자원관리", value: "인적자원관리" },
                { label: "인적자원개발", value: "인적자원개발" },
                { label: "조직체 개선", value: "조직체계 개선" },
                { label: "일터혁신", value: "일터혁신" },
                { label: "제조혁신", value: "제조혁신" },
                { label: "기타", value: "기타" },
              ]}
              {...register("type", { required: true })}
            />
          </div>
          <div className="mb-5">
            <TextField
              label="무엇을 도와드릴까요?"
              multiline={5}
              placeholder="궁금한 점을 남겨주세요."
              {...register("description")}
            />
          </div>
          <div className="mb-5">
            <div className="flex flex-row flex-wrap items-center">
              <label htmlFor="privacy-policy">
                <input
                  type="checkbox"
                  className="mr-2"
                  id="privacy-policy"
                  required
                  {...register("privacy-policy", { required: true })}
                />
                <span className="text-gray-500 leading-snug">
                  개인정보수집 및 이용약관에 동의합니다.
                </span>
              </label>
            </div>
          </div>
        </div>
        <button
          disabled={sending}
          type="submit"
          className={`py-3 block w-full px-4 bg-gray-900 text-white rounded-lg`}
        >
          {sending ? "전송중..." : "무료 상담신청"}
        </button>
      </form>{" "}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={saved}
        autoHideDuration={3000}
        onClose={() => setSaved(false)}
      >
        <Alert severity="success">
          문의내용을 성공적으로 전송했습니다. 빠른 시일내 검토 후 회신
          드리겠습니다.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert severity="error">
          서버에 응답이 없습니다. 대표번호로 연락을 주시거나 잠시후 다시
          시도해주세요.
        </Alert>
      </Snackbar>
    </>
  )
}

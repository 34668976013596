import React, { forwardRef } from "react"
import Labelled from "components/ui/Labelled"

function Select({ label, id, name, required, options, onChange }, ref) {
  return (
    <Labelled label={label} id={id} required={required}>
      <select
        ref={ref}
        name={name}
        id={id}
        className="py-3 bg-gray-100 w-full border-0"
        required={required}
      >
        {options &&
          options.map(option => {
            const { label, value } = option
            return (
              <option key={value} value={value}>
                {label}
              </option>
            )
          })}
      </select>
    </Labelled>
  )
}

export default forwardRef(Select)
